import React, {useEffect, useState} from 'react';
import {upperFirst, camelCase } from "lodash";
import axios from 'axios';
import '../assets/css/mystyles.css';
import qoreContext from '../qoreContext';
import {
    Button,
    Box,
    Heading,
    Text,
    Checkbox,
    useToast,
    useMediaQuery,
    Image,
} from "@chakra-ui/react"

import DateInput from './fields/dateInput';
import DropdownInput from './fields/dropdownInput';
import TextInput from './fields/textInput';
import SuccessModal from './modals/successModal';

function FormPage () {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [nomorHandphone, setNomorHandphone] = useState("")
    const [FieldsbyForms, setFieldsbyForms] = useState([]);
    const [FieldsbyFormsResult, setFieldsbyFormsResult] = useState([]);
    const [FieldFilter, setFieldFilter] = useState({formId: "init"});
    const [emptyFieldsWarning, setEmptyFieldsWarning] = useState([{field: 'init', fieldIndex: null}]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

    const [baseUrl] = useState('https://p-qore-dot-pti-feedloop.et.r.appspot.com');
    const [projectToken, setProjectToken] = useState(null);
    const [tokenUser, setTokenUser] = useState(null);
    const [selectedOrgs, setSelectedOrgs] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectName] = useState('Emina DAQ');

    const toast = useToast();

    let { id } = 'eminaIntro';

    const [filterProps] = useState({
        slugLink: id,
    });

    useEffect(() => {
        let ignore = false;
        const getToken = async () => {
            await axios.post(`${baseUrl}/login`, {
                email: process.env.REACT_APP_MAIL,
                password: process.env.REACT_APP_WORD,
            })
            .then((result) => {
                if (!ignore) {
                    setTokenUser(result.data.token);
                }
            });
        };
    
        getToken();
    
        return () => { ignore = true; };
    }, [baseUrl]);
    
    useEffect(() => {
        let ignore = false;
        const getOrgs = async () => {
            if (tokenUser) {
                await axios.get(`${baseUrl}/orgs`,
                {
                    headers: {
                        Authorization: `Bearer ${tokenUser}`,
                    },
                })
                .then((result) => {
                    const organizationsList = result.data.nodes;

                    const filteredOrganizationsList = organizationsList.find(
                        (data) => data.name === "Paragon Technology and Innovation"
                    );

                    if (!ignore) {
                        setSelectedOrgs(filteredOrganizationsList.id);
                    }
                });
            }
        };
    
        getOrgs();
    
        return () => { ignore = true; };
    }, [baseUrl, tokenUser]);
    
    useEffect(() => {
        let ignore = false;
        const getProjects = async () => {
            if (selectedOrgs && tokenUser) {
                await axios.get(`${baseUrl}/orgs/${selectedOrgs}/projects`,
                {
                    headers: {
                    Authorization: `Bearer ${tokenUser}`,
                    },
                })
                .then((result) => {
                    if (!ignore) {
                    const selected = result.data.nodes.filter((project) => project.name === projectName);
                    setSelectedProject(selected[0].id);
                    }
                });
            }
        };
    
        getProjects();
    
        return () => { ignore = true; };
    }, [baseUrl, selectedOrgs, tokenUser, projectName]);
    
    useEffect(() => {
        let ignore = false;
        const getTokenProject = async () => {
            if (selectedOrgs && tokenUser && selectedProject && baseUrl) {
                await axios({
                    method: 'post',
                    url: `${baseUrl}/orgs/${selectedOrgs}/projects/${selectedProject}/authenticate`,
                    headers: {
                        Authorization: `Bearer ${tokenUser}`,
                    },
                    data: {
                        token: tokenUser,
                    },
                })
                .then((result) => {
                    if (!ignore) {
                        setProjectToken(result.data.token);
                    }
                });
            }
        };
    
        getTokenProject();
    
        return () => { ignore = true; };
    }, [baseUrl, selectedOrgs, tokenUser, selectedProject]);

    const {data: FormList, status} = qoreContext.views.allForm.useListRow({
        order: "desc",
        ...filterProps,
    });

    const {data: emailList, mailStatus} = qoreContext.views.allEmail.useListRow({
        order: "asc",
    });

    useEffect(() => {
        if (FormList.length > 0) {
            setFieldFilter(FieldFilter => ({ ...FieldFilter, formId: FormList[0].id }));
        }
    }, [FormList])

    const {data: FieldList} = qoreContext.views.allFields.useListRow({
        order: "desc",
        ...FieldFilter,
    });

    useEffect(() => {
        document.title = "Kenalan Dulu Yuk!";
    }, [])

    useEffect(() => {
        async function fetchData() {
            if (FormList[0] && FieldList.length) {

                let idForm = FormList[0].id;
                let FieldsbyForms2 = [];
                let FieldsbyFormsResult2 = [];
                let FieldIndex = 0;

                for (FieldIndex = 0; FieldIndex < FieldList.length; FieldIndex++){
                    if (FieldList[FieldIndex].fieldType.displayField.includes("Short Answer") ||
                        FieldList[FieldIndex].fieldType.displayField.includes("Long Answer") ||
                        FieldList[FieldIndex].fieldType.displayField.includes("Multiple Choice") ||
                        FieldList[FieldIndex].fieldType.displayField.includes("Dropdown") ||
                        FieldList[FieldIndex].fieldType.displayField.includes("Checkbox") ||
                        FieldList[FieldIndex].fieldType.displayField === "Date"
                    ) {
                        FieldsbyForms2.push({
                            form_id: idForm,
                            field_id: FieldList[FieldIndex].id,
                            form_name: FieldList[FieldIndex].formId.displayField,
                            slugField: FieldList[FieldIndex].slugField,
                            title: FieldList[FieldIndex].title,
                            field_type: FieldList[FieldIndex].fieldType,
                            indexField: FieldList[FieldIndex].indexField,
                            isRequired: FieldList[FieldIndex].isRequired,
                            multipleAnswer: FieldList[FieldIndex].multipleAnswer,
                            description: FieldList[FieldIndex].description,
                            image: FieldList[FieldIndex].image,
                            type: "primitive",
                            isReadOnly: FieldList[FieldIndex].isReadOnly,
                            defaultAnswer: FieldList[FieldIndex].defaultAnswer,
                            inputType: FieldList[FieldIndex].inputType
                        })
                    } else {
                        FieldsbyForms2.push({
                            form_id: idForm,
                            field_id: FieldList[FieldIndex].id,
                            form_name: FieldList[FieldIndex].formId.displayField,
                            slugField: FieldList[FieldIndex].slugField,
                            title: FieldList[FieldIndex].title,
                            field_type: FieldList[FieldIndex].fieldType,
                            predefinedFieldType: FieldList[FieldIndex].inputType,
                            indexField: FieldList[FieldIndex].indexField,
                            isRequired: FieldList[FieldIndex].isRequired,
                            multipleAnswer: FieldList[FieldIndex].multipleAnswer,
                            description: FieldList[FieldIndex].description,
                            image: FieldList[FieldIndex].image,
                            type: "predefined",
                            isReadOnly: FieldList[FieldIndex].isReadOnly,
                            defaultAnswer: FieldList[FieldIndex].defaultAnswer,
                        });
                    }
                }

                let SortedFieldList = FieldList.sort((c,d)=> (c.indexField > d.indexField ? 1 : -1));

                var a = null;

                for (let PredefinedIndex = 0; PredefinedIndex < SortedFieldList.length ; PredefinedIndex++) {
                    if (SortedFieldList[PredefinedIndex].formId.id === idForm) {
                        a = SortedFieldList[PredefinedIndex].slugField.toString();
                        FieldsbyFormsResult2.push({[a]: null})
                    }
                }

                setFieldsbyForms(FieldsbyForms2.sort((a,b)=> (a.indexField > b.indexField ? 1 : -1)));                
                setFieldsbyFormsResult(FieldsbyFormsResult2);

            } else if (status === "success" && FormList.length === 0) {
                window.location.href = "/";
            }
        }

        fetchData();

    }, [FormList, FieldList, emailList, mailStatus, status])

    const DateChangeHandler = (index, event) => {
        var tempFieldsbyFormsResult = FieldsbyFormsResult;

        let date = new Date(event.target.value);
        let finalDate = date.toLocaleDateString('en-GB');

        tempFieldsbyFormsResult[index][event.target.name] = finalDate;

        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const dropdownChangeHandler = ({ index, fieldName, value }) => {
        var tempFieldsbyFormsResult = [...FieldsbyFormsResult];
        tempFieldsbyFormsResult[index][fieldName] = value;
        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const textInputChangeHandler = (index, event) => {
        var tempFieldsbyFormsResult = FieldsbyFormsResult;
        tempFieldsbyFormsResult[index][event.target.name] = event.target.value;

        setFieldsbyFormsResult(tempFieldsbyFormsResult);
    };

    const changePhoneNumber = (index, event) => {
        const isNumber = /^\d+$/.test(event.target.value);
        if (isNumber || event.target.value === "") {
            const tempFieldsbyFormsResult = FieldsbyFormsResult;
            tempFieldsbyFormsResult[index][event.target.name] = event.target.value;
            setFieldsbyFormsResult(tempFieldsbyFormsResult);
            setNomorHandphone(event.target.value);
        } else {
            setNomorHandphone(nomorHandphone);
        }
    }

    const privacyPolicyHandler = () => {
        setPrivacyPolicy(!privacyPolicy);
    }

    const redirectToSite = () => {
        setSuccessModalOpen(false);
        window.location.replace("https://www.eminacosmetics.com/");
    };

    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
        window.location.reload();
    }

    const validationHandler = () => {
        /* eslint-disable */
       let flag = false;
       FieldsbyFormsResult.forEach((item) => {
           if (item.email) {
              const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.email)
              if (!isEmail) {
                flag = {msg: "Maaf, Format email salah!"}
                setIsSubmitting(false);
              } 
           } else if (item.nomorHandphone) {
                const isNumber = /^\d+$/.test(item.nomorHandphone);
                if (!isNumber || item.nomorHandphone.length <= 10 || item.nomorHandphone.length > 13 ) {
                    flag = {msg: "Maaf, Format phone number salah!"}
                    setIsSubmitting(false);
                } 
           }
       })

       if(flag) {
           toast({
               title: "Error",
               status: "error",
               description: flag.msg,
               duration: 1500,
               position: "top",
               isClosable: true,
           });
           setIsSubmitting(false);
           return false
       } else {
           return true
       }
    };

    const handleSubmit = async() => {
        setIsSubmitting(true);
        const isValid = validationHandler();
        if (!isValid) {
          return;
        }
        var Results = {};
        var FieldName = null;
        var FormResults = FieldsbyFormsResult;
        var FieldDetails = FieldsbyForms;
        let EmptyFieldsToast = false;

        let EmptyFields = [];
        let tempMailerData = [];

        let header = { Authorization: `Bearer ${projectToken}` }

        for (let i = 0; i < FormResults.length ; i++){
            FieldName = Object.keys(FormResults[i]).toString();

            switch (FieldName === FieldDetails[i].slugField) {
                case true:
                    if (FieldDetails[i].predefinedFieldType) {
                        if (Object.values(FormResults[i]).toString() === '') {
                            if (FieldsbyForms[i].isRequired) {         
                                EmptyFieldsToast = true;
                                EmptyFields.push({
                                    field: FieldsbyForms[i].title,
                                    fieldIndex: i
                                });
                                setEmptyFieldsWarning(EmptyFields);
                                break;
                            } else {
                                Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                            }
                        } else {
                            Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                        }

                        EmptyFields.push({
                            field: "OK",
                            fieldIndex: i
                        });

                    } else {                        
                        if (Object.values(FormResults[i]).toString() === '') {
                            if (FieldsbyForms[i].isRequired) {         
                                EmptyFieldsToast = true;
                                EmptyFields.push({
                                    field: FieldsbyForms[i].title,
                                    fieldIndex: i
                                });
                                setEmptyFieldsWarning(EmptyFields);
                                break;
                            } else {
                                Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                            }
                        } else {
                            Object.assign(Results, {[FieldName]: Object.values(FormResults[i]).toString()});
                        }

                        EmptyFields.push({
                            field: "OK",
                            fieldIndex: i
                        });
                    }
                    break;

                case false:
                    break;
                default:
                    toast({
                        title: 'Ada Error, Bung!',
                        status: "error",
                        description: "Terjadi Kesalahan Sistem",
                        duration: 1500,
                        position: "top",
                        isClosable: true,
                    });
                    setIsSubmitting(false);
            }
        }

        if (EmptyFieldsToast === true) {
            toast({
                title: "Error",
                status: "error",
                description: "Maaf, ada isian wajib yang belum terisi, mohon diisi.",
                duration: 1500,
                position: "top",
                isClosable: true,
            });
            setIsSubmitting(false);
        }

        const deleteNonAlfaNumeric = FieldsbyForms[0].form_name.replace(/[^A-Za-z0-9 ]/g, ' ');
        const camelCaseFormatted = upperFirst(camelCase(deleteNonAlfaNumeric));
        let FormLink = `all${camelCaseFormatted}`;

        for (let indexCheck = 0; indexCheck < EmptyFields.length ; indexCheck++){
            if (EmptyFields[indexCheck].field === Object.keys(Results).toString()) {
                setIsSubmitting(false);
                return;
            } else if (EmptyFields[indexCheck].field === "OK") {
                console.log("Clear!")
            } else {
                setIsSubmitting(false);
                return;
            }
        }

        let URL = `${baseUrl}/${selectedProject}/${FormLink}/rows`;
        const mailURL = process.env.REACT_APP_EMAIL_CLIENT;

        tempMailerData.push({
            'mailContent': {
                'email': Results.email,
                'mailSubject': emailList[0].mailSubject,
                'greetingText': emailList[0].greetingText,
                'mailBodyText': emailList[0].mailBodyText,
                'voucherCode': emailList[0].voucherCode,
                'mailHeaderImg': emailList[0].mailHeaderImg,
                'mailFooterText': emailList[0].mailFooterText,
            }
        });

        await axios({
            method: "post",
            url: URL,
            data: Results,
            headers: header,
        })
        .then(() => {
            axios({
                method: "post",
                url: mailURL,
                data: tempMailerData[0],
            })
            .then(async (res) => {
                const resData = await res.data;
                if (resData.status === "success") {
                    console.error("Message Sent");
                    setSuccessModalOpen(true);
                    return;
                } else if (resData.status === "fail") {
                    console.error("Message Not Sent");
                    toast({
                        title: "Telah Terjadi Error",
                        status: "error",
                        description: "Gagal mengirimkan voucher, silahkan dicoba kembali",
                        duration: 1500,
                        position: "top",
                        isClosable: true,
                    });
                    setIsSubmitting(false);
                    return;
                }
            })
            .catch(function (error) {
                console.error(error);
                toast({
                    title: "Telah Terjadi Error",
                    status: "error",
                    description: "Terjadi kesalahan sistem",
                    duration: 1500,
                    position: "top",
                    isClosable: true,
                });
                setIsSubmitting(false);
            })
        })
        .catch(function (error) {
            console.error(error);
            toast({
                title: "Telah Terjadi Error",
                status: "error",
                description: "Terjadi kesalahan sistem",
                duration: 1500,
                position: "top",
                isClosable: true,
            });
            setIsSubmitting(false);
        })
    }

    return(
        <>
        { FormList[0] && FieldsbyForms[0] ?
        <div className="full-page">
            <Box className="form-body" background="#W84c1de" pb={isMobile ? 0 : 6}>
                <Image 
                    src={isMobile ? FormList[0].headerImageMobile : FormList[0].headerImage}
                    alt="Header Banner"
                    pb={isMobile ? 0 : 6}
                    style={{width: '100%'}}
                    display={FormList[0].headerImage ? 'visible' : 'none'}
                />
                <Box
                    width={{ base: "90%", md: "70%", lg: "70%"}}
                    mb={6}
                    mt={6}
                    background="white"
                    overflow="hidden"
                    borderWidth="1px"
                    style={isMobile ? {borderRadius: '24px'} : {borderRadius: '36px'}}
                >
                    <Box
                        align="center"
                        justifyContent="flex-center"
                        padding={isMobile ? "12px 0px 0px 0px" : "24px 56px 0px 56px"}
                        background=""
                    >
                        <Box padding={isMobile ? "12px 0px 0px 0px" : "24px 24px 0px 24px"}>
                            {FieldsbyForms[0] ? 
                            <>
                                <Heading
                                    as="h1"
                                    class="title-font"
                                    size="lg"
                                    style={isMobile ? { fontSize: '28px' } : { fontSize: '36px' }}
                                    mb={FormList[0].description ? 2 : 0}
                                >
                                    {FieldsbyForms[0].form_name}
                                </Heading>
                                {FormList[0].description ? 
                                    <Text fontSize="lg" class="medium-font-style" mb={2}>
                                        {FormList[0].description}
                                    </Text>
                                : null}
                            </>
                            :
                            <>
                                <Heading as="h1" size="lg" mb={2}>
                                    .....
                                </Heading>
                            </>
                            }
                        </Box>
                    </Box>
                    <Box padding={isMobile ? "12px 0px 12px 0px" : "24px 56px 24px 56px"}>
                        {FieldsbyForms ? FieldsbyForms.map((params,index) =>
                        <>
                            <Box key={params.id} padding="18px 24px 18px 24px">
                                <Text mb={2} fontSize="lg" color="#b8435b" className='medium-font-style'>
                                    {params.title}
                                </Text>
                                {params.field_type.displayField === 'Short Answer' || params.field_type.displayField === 'Long Answer' ? (
                                    <TextInput
                                        data={params}
                                        index={index}
                                        textInputChangeHandler={textInputChangeHandler}
                                    /> 
                                )
                                : params.predefinedFieldType === 'text' ? (
                                    <TextInput
                                        data={params}
                                        index={index}
                                        textInputChangeHandler={textInputChangeHandler}
                                    /> 
                                )
                                : params.slugField === 'nomorHandphone' || params.title === 'Nomor Handphone' ? (
                                    <TextInput
                                        data={params}
                                        index={index}
                                        changePhoneNumber={changePhoneNumber}
                                        nomorHandphone={nomorHandphone}
                                    />
                                )
                                : params.field_type.displayField === 'Date' ? (
                                    <DateInput
                                        data={params}
                                        index={index}
                                        DateChangeHandler={DateChangeHandler}
                                    /> 
                                )
                                : params.field_type.displayField === 'Dropdown' ? (
                                    <DropdownInput
                                        data={params}
                                        dropdownChangeHandler={dropdownChangeHandler}
                                        index={index}
                                    /> 
                                )
                                : (
                                    <TextInput
                                        data={params}
                                        index={index}
                                    />
                                )
                                }
                                {FieldsbyForms[index] && emptyFieldsWarning[index] ? 
                                    emptyFieldsWarning[index].field === FieldsbyForms[emptyFieldsWarning[index].fieldIndex ? emptyFieldsWarning[index].fieldIndex : 0].title ? 
                                        <label
                                            className='label-font'
                                        >
                                            Mohon lengkapi isian ini.
                                        </label>
                                    : null 
                                : null }
                            </Box>
                        </>
                        ) : null}
                    </Box>
                    <Box padding="0px 24px 0px 24px" align={isMobile ? "flex-start" : "center"} borderRadius="lg">
                        <Checkbox name="privacyPolicy" onChange={privacyPolicyHandler} value={!privacyPolicy}>
                            <div style={{ position: "relative", marginLeft: "8px" }}>
                                <Text className='medium-black-font-style'>
                                    I have read and agree the&nbsp;
                                    <a
                                        href={FormList[0]? FormList[0].privacyPolicyLink : null}
                                        style={{ color: "#B8435B" }}
                                        className='medium-font-style'
                                    >privacy policy</a>&nbsp;of Emina
                                </Text>
                            </div>
                        </Checkbox>
                    </Box>
                    <Box padding="32px 24px 32px 24px" align="center" justifyContent="flex-center" borderRadius="lg">
                        {privacyPolicy ? (
                            isSubmitting ? 
                                <Button
                                    color='#FFFFFF'
                                    bg='#b8435b'
                                    colorScheme='#b8435b'
                                    isLoading
                                    className='medium-font-style'
                                    loadingText='Sebentar ya, bestie!'
                                    style={isMobile ? { width: "100%" } : null}
                                >
                                    Ambil Hadiah
                                </Button>
                                :
                                <Button
                                    color='#FFFFFF'
                                    bg='#b8435b'
                                    onClick={handleSubmit}
                                    style={isMobile ? { width: "100%" } : null}
                                    className='medium-font-style'
                                >
                                    Ambil Hadiah
                                </Button>
                            )
                        : (
                            <Button
                                color='#FFFFFF'
                                bg='#b8435b'
                                onClick={handleSubmit}
                                style={isMobile ? { width: "100%" } : null}
                                className='medium-font-style'
                                disabled
                            >
                                Ambil Hadiah
                            </Button>
                        )}
                    </Box>
                    <SuccessModal
                        isSuccessModalOpen={isSuccessModalOpen}
                        closeSuccessModal={closeSuccessModal}
                        redirectToSite={redirectToSite}
                        isMobile={isMobile}
                        thankYouImage={FormList[0] ? FormList[0].thankyouImage : null}
                    />
                </Box>
            </Box>
        </div>
        : null
        }
        </>

    );
}

export default FormPage;