import React from 'react';
import {
    Input,
    Textarea,
} from "@chakra-ui/react"

import PropTypes from 'prop-types';

const TextInput = ({data, index, nomorHandphone, textInputChangeHandler, changePhoneNumber}) => {
    return (
        <>
            {data.field_type.displayField === 'Short Answer' ? (
                <Input 
                    className="input"
                    color="#B8435B"
                    id={data.slugField}
                    name={data.slugField}
                    resize="vertical"
                    type="text"
                    variant="flushed"
                    placeholder={data.description}
                    onChange={(event) => textInputChangeHandler(index, event)}
                    style={{fontFamily: "Pangram Medium", fontSize: "medium", color: "#74777b"}}
                />
            )
            : data.field_type.displayField === 'Long Answer' ? (
                <Textarea 
                    className="input"
                    id={data.slugField}
                    color="#B8435B"
                    name={data.slugField}
                    type="text"
                    resize="vertical"
                    placeholder={data.description}
                    onChange={(event) => textInputChangeHandler(index, event)}
                    style={{fontFamily: "Pangram Medium", fontSize: "medium", color: "#74777b"}}
                />  
            )
            : data.title === 'Nomor Handphone' ? (
                <Input 
                    className="input"
                    id={data.slugField}
                    color="#B8435B"
                    name={data.slugField}
                    type="text"
                    variant="flushed"
                    placeholder={data.description}
                    onChange={(event) => changePhoneNumber(index, event)}
                    style={{fontFamily: "Pangram Medium", fontSize: "medium", color: "#74777b"}}
                    value={nomorHandphone}
                />  
            )   
            : 
                <Input 
                    className="input"
                    color="#B8435B"
                    id={data.slugField}
                    name={data.slugField}
                    type="text"
                    variant="flushed"
                    placeholder={data.description}
                    onChange={(event) => textInputChangeHandler(index, event)}
                    style={{fontFamily: "Pangram Medium", fontSize: "medium", color: "#74777b"}}
                />
            }
        </>
    );
};

TextInput.propTypes = {
    data: PropTypes.instanceOf(Object),
    index: PropTypes.number,
    nomorHandphone: PropTypes.string,
    textInputChangeHandler: PropTypes.func,
    changePhoneNumber: PropTypes.func
};

TextInput.defaultProps = {
    data: {},
    index: 0,
    nomorHandphone: null,
    textInputChangeHandler: () => {},
    changePhoneNumber: () => {}
};

export default TextInput;
