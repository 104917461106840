import React, {useState} from 'react';
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react';

import { Autocomplete } from "@material-ui/lab";
import { ChevronDownIcon } from "@chakra-ui/icons";
import PropTypes from 'prop-types';

const DropdownInput = ({data, index, dropdownChangeHandler }) => {

    const [thisMonthProductList] = useState(data.multipleAnswer.choice);

    return (
        <>
        <Autocomplete
            options={thisMonthProductList}
            id="custom-input-demo"
            style={{fontFamily: "Pangram Medium", fontSize: "medium", color: "#74777b"}}
            getOptionLabel={(option) => option.text}
            onChange={(_, values) =>
            dropdownChangeHandler({index, fieldName: data.slugField, value: values.text})
            }
            renderInput={(renderData) => (
            <div ref={renderData.InputProps.ref}>
                <InputGroup size="md">
                    <Input
                        placeholder={data.description}
                        {...renderData.inputProps}
                        color="#A9A9A9"
                    />
                    <InputRightElement>
                        <ChevronDownIcon />
                    </InputRightElement>
                </InputGroup>
            </div>
            )}
        />
        </>
    );
};

DropdownInput.propTypes = {
    data: PropTypes.instanceOf(Object),
    index: PropTypes.number,
    dropdownChangeHandler: PropTypes.func,
};

DropdownInput.defaultProps = {
    data: {},
    index: 0,
    dropdownChangeHandler: () => {},
};

export default DropdownInput;
