import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    Box,
} from "@chakra-ui/react"

import PropTypes from 'prop-types';

const SuccessModal = ({isSuccessModalOpen, closeSuccessModal, redirectToSite, isMobile, thankYouImage}) => {
    return (
        <>
            <Modal isOpen={isSuccessModalOpen} size={isMobile ? "sm" : "2xl"} onClose={() => closeSuccessModal()}>
                <ModalOverlay />
                <ModalContent style={{backgroundColor: '#FDF7D3'}}>
                    <ModalBody align="center" justifyContent="flex-center" pr={6} pl={6}>
                        <Box width="100%" pb={6} pt={6}>
                            {/* <Text color='#B194C4' style={{ fontSize: '24px' }} pb={4} className='title-font'>
                                Yey!<br/>Selamat kamu mendapatkan<br/><span style={{color: '#F78C94'}}>voucher spesial</span> dari Emina
                            </Text> */}
                            {/* <Image src={voucherImage} mb={3} mt={3} boxSize="80%"/> */}
                            <video autoPlay loop muted playsInline>
                                <source src={thankYouImage} type="video/mp4"/>
                            </video>
                            {/* <Text fontSize='14pt' pt={6} color='#B194C4' className='medium-font-style'><b>Klaim dan pakai voucher mu sekarang juga!</b></Text> */}
                        </Box>
                        <Box width="100%" pb={6}>
                            <Button
                                style={isMobile ? { width: "100%" } : { width: "50%", position: 'relative' }}
                                color='#FFFFFF'
                                bg='#F78C94'
                                onClick={() => redirectToSite()}
                                className='medium-font-style'
                            >
                                Belanja Sekarang
                            </Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>  
        </>
    );
};

SuccessModal.propTypes = {
    thankYouImage: PropTypes.string,
    isMobile: PropTypes.bool,
    isSuccessModalOpen: PropTypes.bool,
    closeSuccessModal: PropTypes.func,
    redirectToSite: PropTypes.func,
};

SuccessModal.defaultProps = {
    thankYouImage: null,
    isMobile: false,
    isSuccessModalOpen: false,
    closeSuccessModal: () => {},
    redirectToSite: () => {},
};

export default SuccessModal;
